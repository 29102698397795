import React from 'react'

import 'styles/loader.scss'
require('styles/' + process.env.REACT_APP_FONT + '.scss');

class App extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			app: null,
			loader: true
		}
	}

	async componentDidMount() {
		const { default: AppMain } = await import('./AppMain')
		this.setState({
			app: <AppMain />,
			loader: false
		})
	}

	render() {
		const Loader = ({ fill }) => (
			<svg className={ this.state.loader ? 'fade-up' : '' } fill={ fill } version="1.1"
			x="0px" y="0px" width="385px" height="842px" viewBox="-39 0 387 35"
			overflow="visible" enableBackground="new -10.209 -4.393 385 842">
		<defs>
		</defs>
		<path d="M29.446,37.333h-6.31l-0.526-0.526V22.084l-0.526-0.526H7.887l-0.526,0.526v14.723l-0.526,0.526h-6.31L0,36.807V1.052
			l0.526-0.526h6.31l0.526,0.526v13.671l0.526,0.526h14.197l0.526-0.526V1.052l0.526-0.526h6.31l0.526,0.526v35.755L29.446,37.333z"/>
		<path d="M54.158,37.859c-11.042,0-17.878-7.887-17.878-18.93C36.28,7.887,43.116,0,54.158,0C65.2,0,72.036,7.887,72.036,18.929
			C72.036,29.972,65.2,37.859,54.158,37.859z M54.158,6.31c-6.835,0-10.516,5.258-10.516,12.62c0,7.362,3.681,12.62,10.516,12.62
			c6.836,0,10.517-5.258,10.517-12.62C64.674,11.568,60.994,6.31,54.158,6.31z"/>
		<path d="M117.254,37.333h-6.31l-0.526-0.526V16.3h-0.525l-8.413,20.507l-0.526,0.526H95.17l-0.526-0.526L86.231,16.3h-0.526v20.507
			l-0.526,0.526h-6.31l-0.526-0.526V1.052l0.526-0.526h7.887l0.526,0.526l10.516,25.765h0.526l10.517-25.765l0.526-0.526h7.887
			l0.526,0.526v35.755L117.254,37.333z"/>
		<path d="M151.43,37.333h-24.713l-0.526-0.526V1.052l0.526-0.526h24.713l0.526,0.526V6.31l-0.526,0.526h-17.352l-0.526,0.526v7.361
			l0.526,0.526h16.3l0.526,0.526v5.258l-0.526,0.526h-16.3l-0.526,0.526v8.413l0.526,0.526h17.352l0.526,0.525v5.258L151.43,37.333z"
			/>
		<path d="M177.72,37.333h-17.878l-0.526-0.526V1.052l0.526-0.526h16.826c5.258,0,10.516,2.629,10.516,9.464
			c0,3.681-1.577,6.836-5.258,7.888v0.525c4.207,1.052,6.31,4.207,6.31,8.939C188.236,34.704,182.978,37.333,177.72,37.333z
			M175.616,6.835h-8.413l-0.526,0.526v7.361l0.526,0.526h8.413c3.155,0,4.207-2.103,4.207-4.207
			C179.823,8.413,178.771,6.835,175.616,6.835z M175.616,21.559h-8.413l-0.526,0.526v8.413l0.526,0.526h8.413
			c3.681,0,5.258-1.578,5.258-4.732C180.875,23.136,179.297,21.559,175.616,21.559z"/>
		<path d="M217.155,22.084v0.526l6.836,8.938v5.258l-0.526,0.526h-3.155l-1.051-0.526l-10.517-13.671l-1.052-0.525h-5.258
			l-0.526,0.525v13.671l-0.526,0.526h-6.31l-0.526-0.526V1.052l0.526-0.526h17.352c7.361,0,12.619,3.681,12.619,11.042
			C225.042,17.352,221.361,21.033,217.155,22.084z M212.422,6.835h-9.991l-0.526,0.526v8.413l0.526,0.526h9.991
			c3.154,0,5.258-1.052,5.258-4.732S215.577,6.835,212.422,6.835z"/>
		<path d="M256.59,37.333h-24.714l-0.526-0.526V1.052l0.526-0.526h24.714l0.525,0.526V6.31l-0.525,0.526h-17.353l-0.525,0.526v7.361
			l0.525,0.526h16.301l0.525,0.526v5.258l-0.525,0.526h-16.301l-0.525,0.526v8.413l0.525,0.526h17.353l0.525,0.525v5.258
			L256.59,37.333z"/>
		<path d="M288.664,0.526l0.526,0.526l6.836,24.188h0.525l5.258-24.188l0.525-0.526h5.258l0.527,0.526V6.31l-7.889,30.498
			l-0.525,0.526h-6.836l-0.525-0.526l-6.835-25.239h-0.525l-6.836,25.239l-0.526,0.526h-6.835l-0.526-0.526L262.373,6.31V1.052
			l0.525-0.526h5.259l0.525,0.526l5.258,24.188h0.526l6.835-24.188l0.526-0.526H288.664z"/>
		</svg>
		)

		let loaderStyle = `loader ${ this.state.loader ? '' : 'fade-out' }`

		return(
			<div className='tank'>
				{ this.state.app }
				<div className={loaderStyle}><Loader fill='#eeeeee' /></div>
			</div>
		)
	}
}

export default App